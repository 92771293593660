<template>
  <OnboardPage 
    :content="content"
    :deal="deal"
  >  
    <form @submit.prevent="onSubmit">
    <div class="field">
      <label 
        class="checkbox is-block"
        
      >
        <input 
          type="checkbox"
          v-model="deal.is_repeatable"
        >
        <b> More than one per customers</b> <br/> <span class="is-size-7">If left checked this will allow a customer to receive the reward multiple times up to a limit of once per 24 hour period. If unchecked each customer may only use this deal one time.</span>
        
      </label>
      <label 
        class="checkbox is-block"
        style="margin-top:10px"
      >
        <input 
          type="checkbox"
          v-model="deal.is_condition"
        >
        <b> Cannot be combined with other offers </b>
      </label>
    </div>
      <BaseSubmit
        label="Next"
        :disabled="false"
        :submitting="submitting"
      />
    </form>
    <div
     style="margin-top:20px;"
    >
      <a
        v-if="content.showBack" 
        class="is-size-7 has-text-grey"
        @click="back()"
      >
        &larr;
        back
      </a>
    </div>
  </OnboardPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import OnboardPage from "@/components/OnboardPage.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";

export default {
  components: {
    OnboardPage,
    BaseSubmit
  },
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      deal: {
        is_repeatable: true,
        is_condition: true,
      },
      content: {
        page: 'organization-deal-disclaimers',
        step: 3,
        title: 'Limits and disclaimers',
        subtitle: 'Select any that apply. You may change later.',
        //nextPage: "organization-award-method",
        nextPage: "organization-fund",
        showBack: true
      }
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
    currentDeal: function() {
      return this.$store.getters.currentDeal;     
    },
  },
  mounted() {
    if (this.currentBusiness && 
        this.currentBusiness.slug && 
        this.currentDeal &&
        this.currentDeal.slug
      ) {
      
      if (this.currentBusiness.is_onboarded == true) {
        this.$router.push('/deals/user/my-location/100')
      } else {
        this.deal = this.currentDeal
      }  

    } else {
      this.$router.push("/o/organization-new")
    } 
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onSubmit() {
      this.submitting = true;
 
      const params = {
        user_slug: this.currentUser.slug,
        org_slug: this.currentBusiness.slug,
        slug: this.currentDeal.slug,
        deal: {
          is_repeatable: this.deal.is_repeatable,
          is_condition: this.deal.is_condition
        }
      }
      this.$store.dispatch("updateDeal", params)
        .then(() => {
          this.$router.push('/o/' + this.content.nextPage)
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.error(error)
            this.error = error
          }
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>
